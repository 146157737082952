
import React, { Component } from 'react';
import './App.css';
import Button from './Components/Button'
import Input from './Components/Input'
import DatePicker, { registerLocale } from "react-datepicker";
import Fr from "date-fns/locale/fr";
import WebServices from './WebServices'
import "react-datepicker/dist/react-datepicker.css";
import Address from './Components/Address'
import swal from '@sweetalert/with-react'
import Summary from './Components/Summary'
import moment from 'moment';
import fr from 'moment/locale/fr';
import StripeHandler from './StripeHandler'
import CheckButton from './Components/CheckButton'
import 'react-credit-cards/es/styles-compiled.css';
import CreditCardInput from 'react-credit-card-input';
import SvgRefresh from './assets/SvgRefresh'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from 'react-bootstrap';

import SuccessBooking from './Components/SuccessBooking'

const ENV = "DEV"

var stripe = require('stripe-client')('pk_live_hrfRugshSuzvVJKomD8qcq0G');

class App extends Component {
    constructor(props) {

        super(props);

        let today = moment().add(5, 'hours').add(3, 'days').toDate(),
            tomorrow = moment().add(6, 'hours').add(4, 'days').add(30, 'minutes').toDate()

        if (today.getHours() >= 20 || today.getHours() <= 8) {
            today.setHours(11)
            tomorrow.setHours(11)
        }

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            address_from: "",

            payement_online: true,
            payment_to_driver: false,
            address_to: "",

            date: null,
            time: new Date(today.getTime()),

            address_from_focused: false,
            address_to_focused: false,

            search: [],
            search_return: [],

            show: false,
            show_return: false,

            point_from: null,
            point_to: null,


            cvc: '',
            expiry: '',
            focus: '',

            name: '',
            number: '',
            baggages: 0,



            card: null,
            webview: false,
            amount: 0,
            amount_to_pay: 0,
            google_place_from: "",
            google_place_to: "",
            duration: 0,
            coords: null,
            ticket_number: null,
            selected_card: null,
            show_loader: false,
            show_message: false,
            create_card: false,
            trip: null,
            show_add_card: false,
            mybookings: null,
            show_bookings: false,
            show_summary: false,
            checked: [],
            accept: false,
            calculate_loader: false,

            history_id: null,

            passenger_name: '',
            passenger_phone_number: '',
            passenger_email: '',

            registerPro: false,

            showVoucherInput: false,
            voucher: '',
            voucherCode: null,

            passenger_amount: null,
            baggage_amount: null,

            hour_amount: null,
            vtc_amount: null,

            show_order_form: true,

            booking_type: 'trip',

            successBooking: false
        }

        moment.updateLocale('fr', fr);
        registerLocale("Fr", Fr);

    }

    componentDidMount() {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let step = params.get('step');

        if (step == "2") {

            let booking = (JSON.parse(localStorage.getItem('booking')))
            
            console.log(booking)

            this.setState({
                show_order_form: false,
                history_id: booking.history_id,
                date: new Date(booking.date),
                address_from: booking.address_from,
                address_to: booking.address_to,
                baggage_amount: booking.baggage_amount,
                duration: booking.duration,
                distance: booking.distance,
                passenger_amount: booking.passenger_amount,
                amount_to_pay: booking.amount_to_pay,
                amount_to_pay_commission: booking.amount_to_pay_commission,
                amount_to_pay_driver: booking.amount_to_pay_driver,

                booking_type: booking.booking_type,
                vtc_amount: booking.vtc_amount,
                hour_amount: booking.hour_amount

            })




        }

        window.addEventListener("resize", () => {

            if (!this.state.address_from_focused && !this.state.address_to_focused) {
                this.setState({ width: window.innerWidth, height: window.innerHeight })
            }

            this.sendNewSize()

        });

        window.addEventListener('message', (e) => {

            if (e.data.type === "loaded") {
                this.setState({ show_loader: false })
            }

            if (e.data === "Etherial::EZEE-S::Success") {

                this.updateTrip()

                this.setState({ webview: false })

            }

        });

        setTimeout(() => {
            this.sendNewSize()
        })

    }

    sendNewSize() {

        window.parent.postMessage({
            "name": "resize",
            "scrollWidth": window.document.body.scrollWidth,
            "scrollHeight": window.document.body.scrollHeight,
        }, "*");

    }

    componentWillUnmount() {
        window.removeEventListener('resize');
    }

    resetForm() {

        this.setState({
            address_from: "",
            address_to: "",
            baggages: null,
            passenger_amount: null,
            amount_to_pay: 0,
            hour_amount: null,
            vtc_amount: null,
        })
    }

    updateTrip() {
        WebServices.updateTrip({ status: 2 }, this.state.trip.id).then((res) => {
            if (res.status === 202) {
                // this.resetForm()
                // swal({
                //     title: "",
                //     text: "Votre réservation a bien été enregistrée, les instructions ont été envoyé par e-mail.",
                //     type: "success",
                //     showCancelButton: false,
                //     closeOnConfirm: true,
                //     closeOnClickOutside: false
                // }).then(() => {
                //     window.location.reload()
                // });

                this.setState({successBooking: true})

            } else {
                swal("Une erreur s'est produite !", "", "error")
            }
        });
    }

    onLocationChange(event) {

        this.setState({ amount_to_pay: 0 })
        let text = event.target.value

        if (this.state.address_from_focused) {
            this.setState({ address_from: text });
        } else {
            this.setState({ address_to: text });
        }

        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(() => {
            this.finishTyping(text);
        }, 200);


    }


    finishTyping(text) {
        WebServices.getKeyWordPlaces({ keyword: text }).then((res) => {
            if (res.status === 200) {
                if (this.state.address_from_focused) {
                    this.setState({ search: res.data, show: true, show_return: false });
                } else {
                    this.setState({ search_return: res.data, show_return: true, show: false });
                }
            }
        });
    }


    getPoints(address) {

        this.setState({ amount_to_pay: 0 }, () => {
            if (this.state.address_from_focused) {
                this.setState({ show: false, address_from: address.formatted_address, google_place_from: address.google_place_id })
            } else {
                this.setState({ show_return: false, address_to: address.formatted_address, google_place_to: address.google_place_id })
            }
        })

    }

    getPricing() {
        this.setState({ calculate_loader: true })
        if (this.state.address_from.length > 0) {

            let dmoment = moment(this.state.date)
            let hmoment = moment(this.state.time)

            WebServices.getPricing(
                this.state.google_place_from,
                this.state.google_place_to,
                dmoment.isoWeekday(),
                hmoment.hour(),
                this.state.passenger_amount || 0,
                this.state.hour_amount || 0,
                this.state.booking_type
            ).then((res) => {
                if (res.status === 200) {

                    this.setState({

                        amount_to_pay: (res.data.amount_to_pay.total / 100).toFixed(2),
                        amount_to_pay_commission: (res.data.amount_to_pay.commission / 100).toFixed(2),
                        amount_to_pay_driver: (res.data.amount_to_pay.price / 100).toFixed(2),


                        duration: (parseInt(res.data.duration) / 60).toFixed(0),

                        history_id: res.data.history_id,
                        calculate_loader: false,
                        distance: (parseInt(res.data.distance / 1000)).toFixed(1)
                    }, () => {

                        this.sendNewSize()

                    })
                } else {
                    this.setState({ calculate_loader: false })
                    swal("Une erreur s'est produite !", "", "error")
                }
            })
        }
    }

    confirm() {

        this.createCard().then((token) => {

            let trip = {
                history_id: this.state.history_id || 0,
                passenger_amount: this.state.passenger_amount || 0,
                baggage_amount: this.state.baggage_amount || 0,

                vtc_amount: this.state.vtc_amount || 0,
                hour_amount: this.state.hour_amount || 0,

                trip_date: this.state.date.toISOString(),
                comment: this.state.comment,
                stripe_token: token.stripe_source,
                passenger_name: this.state.passenger_name,
                passenger_email: this.state.passenger_email,
                passenger_phone_number: this.state.passenger_phone_number,
                type: this.state.booking_type,

                address_from: this.state.address_from
            }

            WebServices.createTrip(trip).then((res) => {
                if (res.status === 200) {

                    this.setState({ trip: res.data, show_loader: false })

                    if (res.data.status != "requires_capture") {
                        this.setState({ webview: true, url: res.data.url })
                    } else {
                        this.resetForm()

                        this.updateTrip()

                    }

                } else {
                    this.setState({ show_loader: false })
                    swal("Une erreur s'est produite !", "", "error")
                }

            });

        })

    }

    createCard() {
        this.setState({ show_loader: true, show_message: true, create_card: true })
        let date = this.state.expiry.split('/');

        return new Promise((resolve, reject) => {
            stripe.createToken({
                card: {
                    number: this.state.number.split(' ').join(''),
                    exp_month: parseInt(date[0]),
                    exp_year: parseInt(date[1]),
                    cvc: this.state.cvc
                }
            }).then((stripeTokenInfo) => {
                stripeTokenInfo.json().then((data) => {
                    let stripe_source = { stripe_source: data.id };
                    if (data.error) {
                        // reject()
                        this.setState({ show_loader: false, show_message: false })
                        swal("Une erreur s'est produite !", `${StripeHandler(data.error.code)}`, "error")
                    } else {
                        resolve(stripe_source)
                    }
                })
            });

        })


    }

    goToPricing() {

        localStorage.setItem("booking", JSON.stringify({
            history_id: this.state.history_id,
            baggage_amount: this.state.baggage_amount,
            passenger_amount: this.state.passenger_amount,

            vtc_amount: this.state.vtc_amount,
            hour_amount: this.state.hour_amount,

            date: this.state.date,
            ticket_number: this.state.ticket_number,

            address_from: this.state.address_from,
            address_to: this.state.address_to,

            duration: this.state.duration,
            distance: this.state.distance,

            amount_to_pay: this.state.amount_to_pay,
            amount_to_pay_commission: this.state.amount_to_pay_commission,
            amount_to_pay_driver: this.state.amount_to_pay_driver,

            booking_type: this.state.booking_type
        }))

        this.setState({
            show_loader: true
        })

        setTimeout(() => {
            window.parent.postMessage("change-page-to-pricing", "*")
        }, 100)

    }


    addVoucher() {
        this.setState({ show_loader: true })
        console.log("this.state.voucherCode: ", this.state.voucherCode)
        WebServices.Vouchers(this.state.voucher).then((res) => {
            console.log("resCode: ", res)
            this.setState({ show_loader: false })
            if (res.status === 200) {
                if (res.data.already_use) {
                    swal("Erreur", "Ce code promo est déjà utilisé !", "error")
                } else {
                    this.setState({
                        voucherCode: {
                            id: res.data.voucher.id,
                            code: res.data.voucher.code,
                            amount: res.data.voucher.amount
                        },

                    })
                }
            } else {
                swal("Erreur", "Le code promo saisi n'existe pas", "error")
            }
        });
    }

    render() {

        if (this.state.successBooking) {
            return <SuccessBooking />
        }

        if (this.state.webview) {
            return (
                <div className="App" >
                    <iframe
                        id="iframe_id"
                        className="iframe"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        style={{ width: "100%", height: '100%', }}
                        src={this.state.url}
                    >
                    </iframe>
                </div>)
        } else {
            return (

                <div
                    className="App"
                    onClick={() => this.setState({ show: false })}
                    style={{ 'max-width': ('1240px') }}
                >

                    {this.state.show_loader && <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}

                    {
                        this.state.show_order_form ?

                            <div className="big-wrapper">

                                <div className="elementor-element elementor-element-5a5fa8d elementor-column elementor-col-50 elementor-top-column" data-id="5a5fa8d" data-element_type="column" style={{
                                    "align-content": "center",
                                    "align-items": "center",
                                }}>
                                    <div className="elementor-column-wrap  elementor-element-populated">
                                        <div className="elementor-widget-wrap">
                                            <div className="elementor-element elementor-element-b3f702f elementor-widget elementor-widget-heading" data-id="b3f702f" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default"
                                                        style={{
                                                            "color": "#FFFFFF",
                                                            "font-size": "17px",
                                                            "font-weight": "400",
                                                            "letter-spacing": "2px",
                                                        }}
                                                    >
                                                        {
                                                            this.state.booking_type === 'trip' ?
                                                                (<>WROOMYDRIVER</>) :
                                                                (<>WROOM SERVICES</>)
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="elementor-element elementor-element-3b2ddfe elementor-widget elementor-widget-heading" data-id="3b2ddfe" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default" style={{
                                                        "color": "#FFFFFF",
                                                        "font-family": "Poppins",
                                                        "font-size": "40px",
                                                        "font-weight": "bold",
                                                        "line-height": "1.4em",
                                                        "letter-spacing": "1px",
                                                    }}>
                                                        {
                                                            this.state.booking_type === 'trip' ?
                                                                (<>Ensemble, prenons<br /> la meilleure route.</>) :
                                                                (<>Réservez<br />ou Conduisez.</>)
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="elementor-element elementor-element-ae2e1c1 elementor-widget elementor-widget-heading" data-id="ae2e1c1" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default" style={{
                                                        "color": "#FFFFFF",
                                                        "font-size": "15px",
                                                        "font-weight": "300",
                                                    }}>
                                                        {
                                                            this.state.booking_type === 'trip' ?
                                                                (<>Réserver ou proposer un service VTC avec des valeurs saine</>) :
                                                                (<>Réservez à l'avance votre VTC avec Wroom Service et déplacez vous dans le confort et la sérénité.</>)
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                            window.parent.postMessage("change-page-to-connexion", "*")
                                                        }} className="elementor-element elementor-element-61a0082 elementor-align-left elementor-tablet-align-left elementor-mobile-align-justify elementor-widget elementor-widget-button" data-id="61a0082" data-element_type="widget" data-widget_type="button.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-button-wrapper">
                                                        
                                                        <a href="#" className="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink" role="button" style={{ background: '#61CE70' }}  >

                                                            <span className="elementor-button-content-wrapper">
                                                                <span className="elementor-button-text" >
                                                                    {
                                                                        this.state.booking_type === 'trip' ?
                                                                            (<>Je suis VTC</>) :
                                                                            (<>Devenir chauffeur partenaire</>)
                                                                    }
                                                                </span>
                                                            </span>

                                                        </a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="booking-block">

                                    {this.state.calculate_loader && <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}

                                    <p className="title" style={{
                                        marginBottom: 5,
                                        "color": "#3F3A64",
                                        "font-size": "20px",
                                        "font-weight": "bold"
                                    }}>Je recherche un ou des VTC</p>

                                    <p className="text" style={{ color: '#8C89A2', 'font-size': '15px' }} > Calculez et réservez à l'avance votre trajet en ligne en toute sécurité avec WrooMyDriver</p>
                                    <div className="booking">

                                        <div className="elementor-field-type-radio elementor-field-group elementor-column elementor-field-group-name elementor-col-100" style={{
                                            color: '#8C89A2',
                                            fontSize: '14px',
                                            marginBottom: '10px',
                                            width: '90%'
                                        }}>

                                            <label for="form-field-name" className="elementor-field-label elementor-screen-only">Type</label>
                                            <div className="elementor-field-subgroup  elementor-subgroup-inline">
                                                <span className="elementor-field-option">
                                                    <input
                                                        type="radio"
                                                        value="Course simple"
                                                        checked={this.state.booking_type === "trip"}
                                                        onChange={() => { this.setState({ booking_type: 'trip' }); this.resetForm() }}
                                                        id="form-field-name-0"
                                                    />
                                                    <label for="form-field-name-0" >Course simple</label>
                                                </span>
                                                <span className="elementor-field-option">
                                                    <input
                                                        type="radio"
                                                        value="Mise à disposition d'un véhicule"
                                                        checked={this.state.booking_type === "car"}
                                                        onChange={() => { this.setState({ booking_type: 'car' }); this.resetForm() }}
                                                        id="form-field-name-1"
                                                    />
                                                    <label for="form-field-name-1">Mise à disposition d'un véhicule</label>
                                                </span>
                                            </div>
                                        </div>
                                        {/* <Form>
                                            <Form.Control
                                             className="inputStyle"
                                                type="text"
                                                placeholder={this.state.booking_type === 'trip' ? "Adresse de départ" : "Adresse de mise à disposition"}
                                                value={this.state.address_from}
                                                ref="address_from_ref"
                                                onChange={this.onLocationChange.bind(this)}
                                                onFocus={() => {
                                                    this.setState({ address_from_focused: true, address_to_focused: false, show_return: false }, () => {

                                                        if (this.state.search.length > 0) {
                                                            setTimeout(() => {
                                                                this.setState({ show: true })
                                                            }, 100)

                                                        }

                                                    })

                                                }}
                                                onBlur={() => {
                                                    this.setState({ show: false })
                                                }}
                                            />
                                        </Form> */}
                                        <Input
                                            placeholder={this.state.booking_type === 'trip' ? "Adresse de départ" : "Adresse de mise à disposition"}
                                            value={this.state.address_from}
                                            onChange={this.onLocationChange.bind(this)}
                                            refinput="address_from_ref"
                                            onFocus={() => {
                                                this.setState({ address_from_focused: true, address_to_focused: false, show_return: false }, () => {

                                                    if (this.state.search.length > 0) {
                                                        setTimeout(() => {
                                                            this.setState({ show: true })
                                                        }, 100)

                                                    }

                                                })

                                            }}
                                            onBlur={() => {
                                                this.setState({ show: false })
                                            }}
                                        />

                                        {this.state.search.length > 0 && this.state.show &&
                                            <div className="list" style={{
                                                top: this.state.width < 1024 ? '12rem' : '16rem',
                                                'boxShadow': 'rgba(0, 0, 0, 0.75) 0px 0px 10px 0px'
                                            }}>
                                                <p className="text">Veuillez séléctionner une adresse </p>
                                                {this.state.search.map((el) =>
                                                    <Address
                                                        address={el.formatted_address}
                                                        address_width={(this.state.height > this.state.width) ? '58vmin' : '20vmax'}
                                                        onClick={() => this.getPoints(el)}
                                                    />
                                                )}
                                            </div>
                                        }

                                        {

                                            this.state.booking_type === 'trip' && (

                                                <Input
                                                    placeholder="Adresse d'arrivée"
                                                    value={this.state.address_to}
                                                    onChange={this.onLocationChange.bind(this)}
                                                    refinput="address_to_ref"
                                                    onFocus={() => {
                                                        this.setState({ address_from_focused: false, address_to_focused: true, show: false }, () => {

                                                            if (this.state.search_return.length > 0) {
                                                                setTimeout(() => {
                                                                    this.setState({ show_return: true })
                                                                }, 100)

                                                            }

                                                        })

                                                    }}
                                                    onBlur={() => {
                                                        this.setState({ show_return: false })
                                                    }}

                                                />
                                            )

                                        }

                                        {this.state.search_return.length > 0 && this.state.show_return &&
                                            <div className="list" style={{
                                                top: 'calc(19rem + 20px)',
                                                'boxShadow': 'rgba(0, 0, 0, 0.75) 0px 0px 10px 0px'
                                            }}>
                                                <p className="text">Veuillez séléctionner une adresse</p>
                                                {this.state.search_return.map((el) =>
                                                    <Address
                                                        address={el.formatted_address}
                                                        address_width={(this.state.height > this.state.width) ? '58vmin' : '20vmax'}
                                                        onClick={() => this.getPoints(el)}
                                                    />
                                                )}
                                            </div>
                                        }

                                        {this.state.google_place_from.length > 0 && this.state.google_place_from === this.state.google_place_to &&
                                            <div className="flex width" style={{ alignItems: "flex-end" }}>
                                                <p className="text" style={{ color: 'red' }}>Même adresse attribuée deux fois</p>
                                            </div>}




                                        <Row className="flex width" style={{  }}>

                                            <Col className="flex" style={{  width: '98%' }} >

                                                <DatePicker
                                                    placeholderText="Date / heure de départ"
                                                    locale="Fr"
                                                    selected={this.state.date}
                                                    style={{backgroundColor:'green'}}
                                                    timeIntervals={15}
                                                    showTimeSelect
                                                    minDate={moment().toDate()}
                                                    // minTime={(moment()).hours(8).minutes(0).toDate()}
                                                    // maxTime={(moment()).hours(20).minutes(0).toDate()}
                                                    timeCaption="Horaire"
                                                    filterTime={(time) => {

                                                        const currentDate = new Date();
                                                        const selectedDate = new Date(time);
                                                        const selectedDate2 = moment(selectedDate)

                                                        var hour = new Date().getHours();

                                                        let found = false

                                                        if (currentDate.getTime() < selectedDate.getTime()) {
                                                            
                                                            for (let index = 0; index < 192; index++) {
                                                                if (moment().seconds(0).milliseconds(0).minutes(0).add(index * 15, 'minutes').isSame(selectedDate2)) {
                                                                    found = true
                                                                }
                                                            }

                                                           return !found

                                                        } else {

                                                            return false
                                                        }

                                                    }}

                                                    onChange={(date) => {

                                                        if (moment(date) > moment().seconds(0).milliseconds(0).minutes(0).add(96 * 15, 'minutes')) {

                                                            if (moment(date).format('HH:mm') === "00:00") {
                                                                date = moment(date).minutes(moment().format('mm')).hours(moment().format('HH')).toDate()
                                                            }

                                                            this.setState({ date: date })
                                                        }

                                                    }}
                                                    className="date-picker"
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                />
                                            </Col>
                                          

                                        </Row>

                                        {this.state.booking_type === 'trip' && (

                                            <Row className="flex width" >

                                                <Input
                                                    placeholder="Nombre de personnes"
                                                    value={this.state.passenger_amount}
                                                    onChange={(event) => { this.setState({ passenger_amount: event.target.value }) }}

                                                    onBlur={() => {
                                                        this.setState({ show_return: false })
                                                    }}
                                                    type="number"
                                                    style={{ marginBottom: 0 }}
                                                />

                                                <div style={{ width: '10%' }}></div>

                                                <Input
                                                    placeholder="Nombre de bagages"
                                                    value={this.state.baggage_amount}
                                                    onChange={(event) => { this.setState({ baggage_amount: event.target.value }) }}

                                                    onBlur={() => {
                                                        this.setState({ show_return: false })
                                                    }}
                                                    type="number"
                                                    style={{ marginBottom: 0 }}
                                                />

                                            </Row>

                                        )}

                                        {this.state.booking_type === 'car' && (

                                            // <div className="row width center">
                                            <Row className="flex width center">
                                                <Input
                                                    placeholder="Nombre d'heure"
                                                    value={this.state.hour_amount}
                                                    onChange={(event) => { this.setState({ hour_amount: event.target.value }) }}

                                                    onBlur={() => {
                                                        this.setState({ show_return: false })
                                                    }}
                                                    type="number"
                                                    style={{ marginBottom: 0 }}
                                                />

                                                <div style={{ width: '10%' }}></div>

                                                <Input
                                                    placeholder="Nombre de VTC"
                                                    value={this.state.vtc_amount}
                                                    onChange={(event) => { this.setState({ vtc_amount: event.target.value }) }}

                                                    onBlur={() => {
                                                        this.setState({ show_return: false })
                                                    }}
                                                    type="number"
                                                    style={{ marginBottom: 0 }}
                                                />
                                            </Row>
                                            /* </div> */

                                        )}

                                        {(this.state.amount_to_pay != 0)

                                            &&

                                            <div className="flex pricing-div" >


                                                <Row >

                                                    <Col md={4} sm={2} className="flex flex-start-align">
                                                        <p className='title' style={{ fontSize: '14px', color: "#8FCBB1" }}>PRIX TOTAL</p>
                                                        <p className='text-medium ' style={{ fontSize: '13px', color: '#8C89A2' }}>À payer aujourd'hui</p>
                                                        {this.state.booking_type === 'trip' && (<p className='text-medium ' style={{ fontSize: '13px', color: '#8C89A2' }}>Distance</p>) }
                                                    </Col>

                                                    <Col md={4} sm={2} className="flex flex-end-align">
                                                        <p className='text-semibold green font-size' style={{ fontSize: '14px', color: "#8FCBB1" }}>{this.state.amount_to_pay}€</p>
                                                        <p className='text-semibold green font-size' style={{ fontSize: '13px', color: '#3F3A64' }}>{this.state.amount_to_pay_commission}€</p>
                                                        {this.state.booking_type === 'trip' && (<p className='text-semibold green font-size' style={{ fontSize: '13px', color: '#3F3A64' }}>{this.state.distance} km</p>) }
                                                    </Col>

                                                </Row>

                                            </div>
                                        }


                                        {(this.state.amount_to_pay != 0) &&
                                            <div className="width" style={{ alignItems: 'flex-start', marginTop: 10 }}>
                                                <button className="row center" style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 0, outline: 0 }}
                                                    onClick={() => {
                                                        this.getPricing()
                                                    }}>
                                                    <SvgRefresh />
                                                    <p className="text-medium black" style={{ margin: 0, color: "#222222", marginLeft: 5 }}>Recalculer la course</p>
                                                </button>
                                            </div>
                                        }
                                        <Button
                                            text={"Calculez et Payez en ligne"}
                                            onClick={() => (this.state.amount_to_pay != 0) ? this.goToPricing() : this.getPricing()}
                                            marginBottom={0}
                                            disabled={
                                                this.state.google_place_from.length == 0 ||
                                                (this.state.booking_type === 'trip' && (this.state.google_place_to.length == 0 || this.state.google_place_to == this.state.google_place_from || this.state.passenger_amount < 1)) ||
                                                (this.state.booking_type === 'car' && (this.state.hour_amount < 1 || this.state.vtc_amount < 1))
                                            }
                                        />

                                    </div>

                                </div>
                            </div>

                            :

                            <>

                                <div className="summary ">

                                    <div
                                        className={(window.innerWidth <= 1024) ? "flex center" : "row"}
                                    // style={{ width: ((window.innerWidth > 1024)) && "65vmax" }}
                                    >
                                        <Summary
                                            date={this.state.date}
                                            address_from={this.state.address_from}
                                            address_to={this.state.address_to}
                                            baggage_amount={this.state.baggage_amount}
                                            passenger_amount={this.state.passenger_amount}
                                            duration={this.state.duration}
                                            distance={this.state.distance}
                                            comment={this.state.comment}
                                            user={this.state.user || {}}
                                            onChangeComment={(e) => this.setState({ comment: e.target.value })}

                                            passenger_name={this.state.passenger_name}
                                            onPassengerNameChange={(value) => { this.setState({ passenger_name: value.target.value }) }}

                                            passenger_email={this.state.passenger_email}
                                            onPassengerEmailChange={(value) => { this.setState({ passenger_email: value.target.value }) }}

                                            passenger_phone_number={this.state.passenger_phone_number}
                                            onPassengerPhoneNumberChange={(value) => { this.setState({ passenger_phone_number: value.target.value }) }}

                                            booking_type={this.state.booking_type}

                                            vtc_amount={this.state.vtc_amount}
                                            hour_amount={this.state.hour_amount}

                                        />

                                        <div style={{ marginLeft: 20 }} className="flex min-width-bloc">

                                            <h2 className="text-semibold">VOTRE COMMANDE</h2>
                                            <div className="line " style={{ width: (window.innerWidth <= 1024) ? "50%" : "29vmax", marginBottom: 10 }} />
                                            <Row className="row " >
                                                <Col className="flex" md={4} sm={8} style={{ backgroundColor: 'rgba(0, 180, 86, .1)' }}>
                                                    <p className='text-medium' >A réglé sur place</p>
                                                    <p className='text-medium' >A réglé aujourd'hui</p>
                                                    <p className='text-medium' >Total</p>
                                                </Col>

                                                <Col className="flex flex-end-align">
                                                    <p className='text-semibold green font-size'>{this.state.amount_to_pay_driver}€</p>
                                                    <p className='text-semibold green font-size'>{this.state.amount_to_pay_commission}€</p>

                                                    <p className='text-semibold green font-size'>{this.state.amount_to_pay}€<span className="text font-size"> (Inclut</span> <span className="'title green font-size'">
                                                        {((this.state.amount_to_pay) * 0.2).toFixed(2)}  €</span> <span className="text font-size"> TVA)</span></p>
                                                </Col>
                                            </Row>
                                            <div className="row summary-width center" style={{ backgroundColor: 'rgba(0, 180, 86, .1)', marginTop: ((window.innerWidth <= 1024) ? 10 : (window.innerWidth < 910)) ? 50 : 0, paddingLeft: 10 }}>
                                                <p className="text-medium" >Carte de paiement </p>
                                                <img src={require('./assets/card.png')} style={{ width: '100px', height: '40px' }} />
                                            </div>

                                            <h2 className="text-semibold">VOS MOYENS DE PAIEMENT</h2>
                                            <div className="line " style={{ width: (window.innerWidth <= 1024) ? "50%" : "29vmax", marginBottom: 10 }} />


                                            <div className="flex">
                                                <p className="text-medium" >Payer avec votre carte bancaire avec stripe</p>
                                                <CreditCardInput
                                                    cardNumberInputProps={{ value: this.state.cardNumber, onChange: (value) => { this.setState({ number: value.target.value }) } }}
                                                    cardExpiryInputProps={{ value: this.state.expiry, onChange: (value) => { this.setState({ expiry: value.target.value }) } }}
                                                    cardCVCInputProps={{ value: this.state.cvc, onChange: (value) => { this.setState({ cvc: value.target.value }) } }}
                                                    fieldClassName="input"
                                                    customTextLabels={{
                                                        cardNumberPlaceholder: 'Numéro de la carte',
                                                        expiryPlaceholder: 'MM/AA',
                                                        cvcPlaceholder: 'CVC',
                                                    }}
                                                    inputStyle={{ fontFamily: "Montserrat-Regular", fontSize: '12px', color: '#222222', backgroundColor: '#F9F9F9', }}
                                                    fieldStyle={{ backgroundColor: '#F9F9F9', height: (window.innerWidth <= 1024) && '4vmax', border: '1px solid rgb(196, 196, 196)' }}

                                                />

                                            </div>

                                            <div className="flex summary-width" style={{ marginTop: 20 }}>
                                                <div className='row center' style={{ justifyContent: 'flex-start', alignItems: (window.innerWidth <= 1024) ? 'flex-start' : 'center' }}>
                                                    <CheckButton selected={this.state.accept} onClick={() => this.setState({ accept: !this.state.accept })} />
                                                    <label className="font-size" style={{ color: "#222222", marginLeft: 5 }} onClick={() => this.setState({ accept: !this.state.accept })}>
                                                        J'ai lu et j'accepte les Conditions Générales de Vente *
                                        </label>
                                                </div>


                                                <Button
                                                    text={`RESERVEZ ET PAYER ${this.state.amount_to_pay_commission}€`}
                                                    width={(window.innerWidth <= 1024) ? "70vmin" : '29vmax'}

                                                    onClick={this.confirm.bind(this)}
                                                    disabled={
                                                        !this.state.accept ||
                                                        (this.state.number.length === 0 || this.state.cvc.length === 0 || this.state.expiry.length === 0) ||
                                                        this.state.passenger_name == '' ||
                                                        this.state.passenger_email == '' ||
                                                        this.state.passenger_phone_number == ''

                                                    }
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </>
                    }




                    <ToastContainer autoClose={10000} />
                </div >
        )
        }

    }
}


export default App;
