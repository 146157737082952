import React, { Component } from 'react';
import Button from './Button'
import 'react-credit-cards/es/styles-compiled.css';
import CreditCardInput from 'react-credit-card-input';
import Input from './Input'
import CheckButton from './CheckButton'
import SvgCollaborators from '../assets/SvgCollaborators'
import Select from 'react-select'
import moment from 'moment'
import { Row, Container, Col, Form, FormControl } from 'react-bootstrap';


class Summary extends Component {
    constructor(props) {

        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }
    componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState({ width: window.innerWidth, height: window.innerHeight })
        });
    }

    render() {

        return (

            <Container fluid className="min-width-bloc">
                <div style={{ width: ((window.innerWidth <= 1024)) && '80vmin' }}>

                    <h2 className="text-semibold">INFORMATIONS PERSONNELLES</h2>
                    <div className="line " style={{ width: ((window.innerWidth <= 1024)) ? "50vmin" : "29vmax", marginBottom: 10 }} />
                    <Row className="row " >
                        <Col >
                            <p className='text-medium' >Nom & Prénom:</p>
                        </Col>

                        {/* <p className='text font-size'>{this.props.user.firstname} {this.props.user.lastname}</p> */}
                        <Input
                            placeholder="Nom et Prénom"
                            value={this.props.passenger_name}
                            onChange={this.props.onPassengerNameChange}
                            height_input="36px"
                            style={{ width: '280px' }}
                            name="name"
                        />

                    </Row>
                    <Row className="row ">
                        <p className='text-medium' >Email:</p>
                        <Input
                            placeholder="Email"
                            value={this.props.passenger_email}
                            onChange={this.props.onPassengerEmailChange}
                            height_input="36px"
                            style={{ width: '280px' }}
                            name="email"
                        />
                    </Row>

                    <Row className="row">
                        <p className='text-medium' >Téléphone:</p>
                        <Input
                            placeholder="Numéro de téléphone"
                            value={this.props.passenger_phone_number}
                            onChange={this.props.onPassengerPhoneNumberChange}
                            height_input="36px"
                            style={{ width: '280px' }}
                            name="phone"
                        />
                    </Row>

                    {
                        this.props.booking_type === 'trip' ? (
                            <>
                                <h2 className="text-semibold">VOTRE TRAJET</h2>
                                <div className="line " style={{ width: ((window.innerWidth <= 1024)) ? "50vmin" : "29vmax", marginBottom: 10 }} />

                                <Row className="row ">
                                    <p className='text-medium' >Heure et date:</p>
                                    <p className='text font-size'>{moment(this.props.date).format('LLL')}</p>
                                </Row>

                                <Row className="row ">
                                    <p className='text-medium' >Distance:</p>
                                    <p className='text font-size'>{this.props.distance} Km</p>
                                </Row>
                                <Row className="row ">
                                    <p className='text-medium'>Adresse de départ</p>
                                    <p className='text font-size' style={{ maxWidth: (this.state.height > this.state.width) ? '20vmin' : (window.innerWidth <= 1024) ? '17vmin' : '16vmax', textAlign: "end" }}>{this.props.address_from}</p>
                                </Row>
                                <Row className="row ">
                                    <p className='text-medium' >Adresse d'arrivée</p>
                                    <p className='text font-size' style={{ maxWidth: ((window.innerWidth <= 1024)) ? '20vmin' : '16vmax', textAlign: "end" }}>{this.props.address_to}</p>
                                </Row>
                                <Row className="row ">
                                    <p className='text-medium' >Nombre de passagers :</p>
                                    <p className='text font-size'>{this.props.passenger_amount}</p>
                                </Row>
                                <Row className="row ">
                                    <p className='text-medium' >Nombre de bagages :</p>
                                    <p className='text font-size'>{this.props.baggage_amount}</p>
                                </Row>



                            </>
                        ) : (
                                <>

                                    <h2 className="text-semibold">VOTRE MISE A DISPOSITION</h2>
                                    <div className="line " style={{ width: ((window.innerWidth <= 1024)) ? "50vmin" : "29vmax", marginBottom: 10 }} />

                                    <Row className="row ">
                                        <p className='text-medium' >Heure et date:</p>
                                        <p className='text font-size'>{moment(this.props.date).format('LLL')}</p>
                                    </Row>


                                    <Row className="row ">
                                        <p className='text-medium'>Durée:</p>
                                        <p className='text font-size'>{this.props.hour_amount} heures</p>
                                    </Row>
                                    <Row className="row ">
                                        <p className='text-medium'>Adresse de mise à disposition</p>
                                        <p className='text font-size' style={{ maxWidth: (this.state.height > this.state.width) ? '20vmin' : (window.innerWidth <= 1024) ? '17vmin' : '16vmax', textAlign: "end" }}>{this.props.address_from}</p>
                                    </Row>

                                    <Row className="row ">
                                        <p className='text-medium' >Nombre de vtc :</p>
                                        <p className='text font-size'>{this.props.vtc_amount}</p>
                                    </Row>



                                </>
                            )


                    }


                    <h2 className="text-semibold">INFORMATIONS COMPLÉMENTAIRES</h2>
                    <div className="line " style={{ width: ((window.innerWidth <= 1024)) ? "50vmin" : "29vmax", marginBottom: 10 }} />

                    <p className='text black font-size' style={{ color: "#222222" }}>Informations complémentaires (facultatif)</p>
                    <Input
                        placeholder="Commentaire concernant votre commande"
                        value={this.props.comment}
                        onChange={this.props.onChangeComment}
                        width={((window.innerWidth <= 1024)) ? "70vmin" : '29vmax'}
                        width_input={((window.innerWidth <= 1024)) ? '69vmin' : "28vmax"}
                    />


                </div>
            </Container>
        )
    }
}

export default Summary;